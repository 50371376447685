import { Injectable } from "@angular/core";

@Injectable()
export class GlobalDataService {
    private userData;
    private globalData;

    constructor() {
        this.globalData = { refreshDashboard: false, ...this.globalData }
    }

    setUserData(user) {
        localStorage.setItem('user', JSON.stringify(user));
    }
    setGlobalData(obj) {
        this.globalData = Object.assign(this.globalData, obj);
        localStorage.setItem('globalData', JSON.stringify(this.globalData));
    }
    getLocation(href) {
        var l = document.createElement("a");
        l.href = href;
        return l;
    };
    getOrignalImage = (path) => {
        if (!path)
            return
        let url = this.getLocation(path);
        return url.origin + "/orignal" + url.pathname;

    }

    getUserData() {
        let user = localStorage.getItem("user");
        if (user) {
            return JSON.parse(user);
        }
        return { token: "" }
    }
    getGlobalData() {
        let data = localStorage.getItem("globalData");
        if (data) {
            return JSON.parse(data);
        }
        return {}
    }
    ConvertToCSV(heading, objArray, headerConfig) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = heading + '\r\n';
        let row = '';

        for (let index in headerConfig) {
            row += headerConfig[index].name + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in headerConfig) {
                let head = headerConfig[index].prop || '';
                let value: any = 0;
                if (array[i][head] == undefined && array[i][head] != 0) {
                    value = ''
                } else
                    value = array[i][head];
                line += (value) + ',';
            }
            str += line + '\r\n';
        }
        return str;
    }
    downloadCsv = (csvData, filename = 'data') => {
        let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }
}