import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { CustomSnackbarService } from '../../@fuse/services/error.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';

import { Router } from "@angular/router"
@Injectable()
export class ErrorInterceptorApp implements HttpInterceptor {
    constructor(public toasterService: CustomSnackbarService, private router: Router, private _fuseProgressBarService: FuseProgressBarService) { }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    if (evt.body && evt.body) {
                        this._fuseProgressBarService.hide();
                        //TODO: do some fancy shit here
                    }

                }
            }),
            catchError((err: any) => {
                this._fuseProgressBarService.hide();
                if (err instanceof HttpErrorResponse) {
                    try {
                        if (err.error && err.error.exception == 'Credentials verification failed') {
                            localStorage.clear();
                            this.router.navigate(['/pages/auth/login']);
                        }

                        this.toasterService.open(err.error.exception, 'Cancel');
                    } catch (e) {
                        this.toasterService.open('An error occurred', '');
                    }
                    //log error 
                }
                return of(err);
            }));

    }

}