import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: '',
        translate: '',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'dashboards',
                title: 'Dashboards',
                translate: 'NAV.DASHBOARDS',
                type: 'collapsable',
                icon: 'dashboard',
                children: [

                    {
                        id: 'project',
                        title: 'Project',
                        type: 'item',
                        url: '/apps/dashboards/project'
                    },
                    {
                        id: 'dashboard-report',
                        title: 'Report',
                        type: 'item',
                        url: '/apps/dashboards/report'
                    }
                ]
            },
            {
                id: 'settings',
                title: 'Settings',
                translate: 'NAV.SETTINGS',
                type: 'collapsable',
                icon: 'settings',
                children: [

                    {
                        id: 'segment-settings',
                        title: 'Segment Settings',
                        type: 'item',
                        icon: 'cast_connected',
                        url: '/apps/settings/segment-settings'
                    },
                    {
                        id: 'project-settings',
                        title: 'Project Settings',
                        type: 'item',
                        icon: 'work_outline',
                        url: '/apps/settings/project-settings'
                    },
                    {
                        id: 'contacts',
                        title: 'User Management',
                        type: 'item',
                        icon: 'account_circle',
                        url: '/apps/contacts'
                    }
                ]
            }
        ]
    }
];
