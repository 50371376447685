import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler } from '@angular/common/http';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
    constructor(
        private _fuseProgressBarService: FuseProgressBarService
    ) { }
    getToken() {
        let user = localStorage.getItem("user");
        if (user) {
            let userObject = JSON.parse(user);
            return userObject.token;
        }
    }
    intercept(request: HttpRequest<any>, next: HttpHandler) {
        const token = this.getToken();
        this._fuseProgressBarService.show();
        const authRequest = request.clone({
            setHeaders: { Authorization: `Token ${token}` }
        })
        return next.handle(authRequest);
    }
}